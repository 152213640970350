import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopHeader from './TopHeader';
import BottomFooter from './BottomFooter';
import { setUserInfo } from '@/redux/actions/userInfo';
import { Layout } from 'antd';
import MainContent from './MainContent';
import BreadCrumb from './BreadCrumb';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { WechatLoginParameter } from "@/lib/WechatLoginConfig"
import { getAuthProvider, globalSetParameter } from '@/lib/AuthProvider';
import Login from '@/views/Login';
import NewLogin from '@/views/NewLogin/index';
import supportIcon from '../../assets/img/icon_support.png'

let authProvider = getAuthProvider();
class Index extends Component {
	render() {
		const { breadCrumb } = this.props;
		return (
			<div className="layout">
				<AzureAD provider={authProvider}>
					{
						({ login, logout, authenticationState, error, accountInfo }) => {
							switch (authenticationState) {
								case AuthenticationState.Authenticated:
									return (
										<Layout style={{ minHeight: '100vh' }}>

											<Layout>
												<TopHeader />
												{breadCrumb.show ? <BreadCrumb /> : null}
												<MainContent />
												{/* <div style={{

													position: 'fixed',
													bottom: '20px',
													right: '20px',
													backgroundColor: '#36F',
													fontSize: '14px',
													color: '#ffffff',
													borderRadius: '16px',
													padding: '4px 12px',
													height: "32px",
													zIndex: 999,
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center'
												}} onClick={() => {
													window.easemobim.bind({
														configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
														dialogWidth: "500px",
														hide:true,
													})
												}}>
													<span>联系客服</span>

												</div> */}
												<BottomFooter />
											</Layout>
										</Layout>
									);
								case AuthenticationState.Unauthenticated:
									return (
										<NewLogin Login={login} />
										// <Login Login={login} />
									);
								case AuthenticationState.InProgress:
									return null;
							}
						}
					}
				</AzureAD>
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Index);
