import React from "react";
import './style.scss';
import logoIng from '../../assets/img/Logo.png'
import rightIcon from '../../assets/img/right-icon.png'
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { getAuthProvider, setAuthProvider } from "@/lib/AuthProvider";
import { signinSignupConfig, signinSignupParameter, signinSignupOption } from "@/lib/PhonePwdConfig"
import sensors from 'sa-sdk-javascript/dist/web/sensorsdata.es6';

class NewLogin extends React.Component {
	constructor(props) {
		if (process.env.REACT_APP_ENV === 'prod') {
			sensors.quick('autoTrack')
		}

		super(props);
		this.state = {

		}
	};


	nativeLogin = () => {
		let authProvider1 = new MsalAuthProvider(
			signinSignupConfig, signinSignupParameter, signinSignupOption
		);
		setAuthProvider(authProvider1)

		localStorage.setItem('loginWith', 'native');
		// setTimeout(() => {
		authProvider1.clearCache();
		//authProvider.logout();
		authProvider1.setAuthenticationParameters(signinSignupParameter);

		// invoke a login method, it will actully jump to password reset page
		authProvider1.login();
		// }, 1500)

	}

	render() {
		return (
			<div className='loginBox'>
				{/* {window.innerWidth} */}
				{/* <div className='leftimg'></div> */}
				{window.innerWidth <= 1470 && <img className="leftimg" src="https://download.digital.motion.abb.com.cn/public/portal-img/left-login.png"></img>}
				{window.innerWidth > 1470 && <img className="leftimg" src="https://download.digital.motion.abb.com.cn/public/portal-img/left-login-big.png"></img>}
				<div className="right-cont">
					<div className="img-box">
						<img className="logo-img" src={logoIng}></img>
					</div>
					<div className="login-title">
						<div className="login-title-line"></div>
						<div className="login-title-text">ABB Ability™ 数字化传动链状态监测系统</div>
					</div>
					<div className="login-tips">Motion 365 2.0 全新上线。</div>
					<ol type="disc" className="login-ul-box">
						<li className="login-ul-li">设备巡检</li>
						<li className="login-ul-li">故障诊断</li>
						<li className="login-ul-li">能耗管理</li>
						<li className="login-ul-li">专家服务</li>
					</ol>
					<div className="login-btns">
						<div className="login-left-btn" onClick={() => {
							if (process.env.REACT_APP_ENV === 'prod') {
								sensors.quick('trackHeatMap', this)
							}
							const w = window.open("about:blank");
							w.location.href = process.env.REACT_APP_NEW_URL

						}}> <img className="rightIcon" src={rightIcon}></img> 体验新版</div>
						<div onClick={() => {
							if (process.env.REACT_APP_ENV === 'prod') {
								sensors.quick('trackHeatMap', this);
							}
							this.nativeLogin()
						}} className="login-right-btn">继续使用旧版</div>
					</div>
				</div>
			</div>

		);
	};
}

export default NewLogin;
