
import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { hot } from 'react-hot-loader/root';
import Router from './router/index';
import store from '@/redux/store';
import './assets/css/app';
import './assets/css/common';
import './assets/css/antstyle';
import './assets/css/abbstyle';
import './assets/css/charts';
import intl from 'react-intl-universal';
import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import 'abb-common-ux-react/styles.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import * as ABB from 'abb-common-ux-react';
import sensors from 'sa-sdk-javascript/dist/web/sensorsdata.es6';

const locales = {
	"en-US": require('./locales/en-US.json'),
	"zh-CN": require('./locales/zh-CN.json'),
};
if (process.env.REACT_APP_ENV === 'prod') {
	sensors.init({
		server_url: `https://api.digital.motion.abb.com.cn/sa.gif?project=motion365-old-application&remark=1.9.18`,
		heatmap: {
			scroll_notice_map: 'not_collect',
			clickmap: 'default',
			collect_tags: {
				div: {
					max_level: 2 // 默认是 1，即只支持叶子 div。可配置范围是 [1, 2, 3],非该范围配置值，会被当作 1 处理。
				},
			}
		},
		is_track_single_page: true,
		use_client_time: true,
		send_type: 'beacon',
	});
}


const customizeRenderEmpty = () => (
	//这里面就是我们自己定义的空状态
	<div className="renderEmpty" >
		<ABB.Icon style={{ marginBottom: '16px' }} name="abb/event" sizeClass='large' />
		<p>暂无数据</p>
	</div>
);

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			antdLang: zh_CN,  // 修改antd  组件的国际化
		}
	}
	UNSAFE_componentWillMount() {
		if (process.env.REACT_APP_PROD === 'prod') {
			console.log = function () { }
		}
		//
		this.loadLocales(localStorage.getItem('lang') || 'zh-CN'); // 初始化语言
	}

	loadLocales(lang) {
		intl.init({
			currentLocale: lang,  // 设置初始语音
			locales,
		}).then(() => {
			this.setState({
				antdLang: lang === 'zh-CN' ? zh_CN : en_US
			});
			moment.locale('zh-cn');
		});
	}

	render() {
		return (
			<ConfigProvider
				locale={this.state.antdLang}
				renderEmpty={customizeRenderEmpty}
			>
				<Provider store={store}>
					<Router />

				</Provider>
			</ConfigProvider>

		);
	}
}

export default hot(App);
