import React, { Component } from 'react';
import { Input, Table, Tooltip, Select, message, Form, Spin, Checkbox } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { grantcustomerlist, organizationslist } from '@/api/authorization.js';
import AddAuthorization from './components/addAuthorization.js'
import PostponeAuthorization from './components/postponeAuthorization'
import CancelAuthorization from './components/cancelAuthorization'
import { Collapsible } from 'abb-common-ux-react';

const { Search } = Input;

class Authorization extends Component {
	state = {
		showmodel: {
			visible: false,
			title: '',
			organizationslist: [],
		},
		showmodelPostpone: {
			visible: false,
			id: ''
		},
		showmodelCancel: {
			visible: false,
			name: '',
			id: ''
		},
		pagination: {},
		loading: false,
		pageindex: 1,
		customerlistCopy: [],
		pageSize: 10,
		selectvalue: '',
		organizationslist: [],
		containsExpired: false,
		columns: [
			{
				title: '编号',
				dataIndex: 'addedDate',
				key: '1',
				width: '5%',
				render: (text, record, index) => (
					<span>{index + 1}</span>
				)
			},
			{
				title: '客户名称',
				dataIndex: 'customerName',
				key: '2',
				width: '15%'
			},
			{
				title: '组织类型',
				dataIndex: 'customerType',
				key: '3',
				width: '6%',

			},

			{
				title: '获得API访问权限时间',
				dataIndex: 'grantTime',
				key: '4',
				width: '15%',
				render: (text, record) => (
					<span>{moment.parseZone(record.grantTime).local().format("YYYY/MM/DD HH:mm:ss")}</span>
				)
			},
			{
				title: '添加人',
				dataIndex: 'createdUser',
				key: '5',
				width: '10%'
			},

			{
				title: '授权类型',
				dataIndex: 'authorizationType',
				key: '6',
				width: '8%',

			},
			{
				title: '授权状态',
				dataIndex: 'authrizationState',
				key: '6',
				width: '8%',
			},
			{
				title: 'API授权截止时间',
				dataIndex: 'expiredDate',
				key: '7',
				width: '15%',
				filterIcon: () => (
					<ABB.Icon style={{ color: '#1F1F1F', margin: '7px 33% 0 45px' }} name="abb/filter" sizeClass='small' />
				),
				filterDropdown: (record) => (
					<div style={{ padding: '12px' }}>
						<Checkbox
							// checked={true}

							onChange={this.onChangeCheckboxValue}
							style={{
								fontSize: '14px',
								color: '#1f1f1f'
							}}
						>包含已过期记录</Checkbox>
					</div>
				),
				render: (text, record) => (
					<span>{moment.parseZone(record.expiredDate).local().format("YYYY/MM/DD HH:mm:ss")}</span>
				)
			},
			{
				title: '操作',
				dataIndex: 'operate',
				key: '8',
				width: '15%',
				render: (text, record) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ABB.Button
							// className="ABB_tab_marginTop"
							// disabled={isOrgAdministrator}
							onClick={() => this.showPostponeModel(record)}
							type="primary-blue"
							sizeClass='small' theme="filled"
							text="延期"
						// icon="abb/plus-in-circle"
						/>

						<span
							onClick={() => this.showCancelmodel(record)}
							style={{ cursor: 'pointer', color: '#36f', padding: '8px 16px', minWidth: '100px', marginLeft: 8 }}>取消授权</span>

					</div>
				)
			}
		]
	};
	onChangeCheckboxValue = (e) => {
		this.setState({
			containsExpired: e.target.checked
		},()=>{
			this.getcustomerlist(this.state.selectvalue);

		})
	}
	componentDidMount() {
		this.getcustomerlist(this.state.selectvalue);
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	getorganizationslist = (text) => {
		let url = ``;
		organizationslist(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				this.setState({
					organizationslist: resdata,
					showmodel: {
						visible: true,
						title: text,
						organizationslist: resdata,
					}
				});
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				console.log("getorganizationslist -- organizationslist --接口异常")
				message.error('接口异常');
			}
		});
	}
	getcustomerlist = (text) => {
		let url = `?keywords=${text}&containsExpired=${this.state.containsExpired}`;

		grantcustomerlist(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				this.setState({
					customerlist: resdata,
					customerlistCopy: resdata,
					selectvalue: text
				});
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				console.log("getcustomerlist -- grantcustomerlist --接口异常")
				message.error('接口异常');
			}
		});
	}
	changeList = (e) => {
		let val = e.target.value;
		if (val === '') {
			this.getcustomerlist(val)
		}
		this.setState({
			selectvalue: val
		})
	}
	searchList = (e) => {
		let customerlist = [...this.state.customerlistCopy];
		let filterlist = [];
		if (e === '') {
			this.setState({
				customerlist: customerlist
			})
		}
		else {
			this.getcustomerlist(e)
			// for (let i = 0; i < customerlist.length; i++) {
			// 	if(customerlist[i].customerName.indexOf(e.trim()) !== -1){
			// 		filterlist.push(customerlist[i])
			// 	}
			// }
			// this.setState({
			// 	customerlist: filterlist
			// })
		}

	}
	showPostponeModel = (data) => {
		this.setState({
			showmodelPostpone: {
				visible: true,
				id: data.id
			}
		})
	}
	showCancelmodel = (data) => {
		this.setState({
			showmodelCancel: {
				visible: true,
				id: data.id,
				name: data.customerName
			}
		})
	}
	showAddAuthorization = (text) => {
		this.getorganizationslist(text);
	}
	submitmodel = () => {
		this.getcustomerlist("");
	}
	hidemodel = () => {
		this.setState({
			showmodel: {
				visible: false,
				title: '',
				organizationslist: [],
			},
			showmodelPostpone: {
				visible: false,
				id: ''
			},
			showmodelCancel: {
				visible: false,
				id: '',
				name: ''
			}
		})
	}
	render() {
		let { selectvalue, columns, customerlist, showmodel, showmodelPostpone, showmodelCancel } = this.state;

		return (
			<>
				<div className="user_box ABB_tab_marginTop" style={{ fontSize: 14 }}>
					<ABB.Button
						// className="ABB_tab_marginTop"
						// disabled={isOrgAdministrator}
						onClick={() => this.showAddAuthorization('添加授权客户')}
						type="primary-blue"
						sizeClass='small' theme="filled"
						text="添加授权客户"
						icon="abb/plus-in-circle"
					/>


					<Search
						onChange={(e) => this.changeList(e)}
						onSearch={(e) => this.searchList(e)}
						placeholder="请输入客户名称"
						value={selectvalue}
						style={{ width: 190 }} className="search_input" />



				</div>

				<Table
					size="small"
					scroll={{ x: 740 }}
					columns={columns}
					dataSource={customerlist}
					ellipsis={true}
					pagination={{
						// onChange: page => this.onChange(page),
						pageSize: 10,
						// current: pageindex,
					}}
					rowKey={(record, index) => index}
				/>
				<AddAuthorization
					showmodel={showmodel}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>
				<PostponeAuthorization
					showmodel={showmodelPostpone}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>
				<CancelAuthorization
					showmodel={showmodelCancel}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Authorization));
